<template>
  <el-row>
    <el-row class="banner">
      <img src="../assets/images/banner_about.jpg" alt=""/>
    </el-row>
    <div class="content">
      <el-row class="column">
        <p>关于弘新<i>ABOUT HONGXIN</i></p>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <img class="company" src="../assets/images/company.png" alt=""/>
        </el-col>
        <el-col :span="12">
          <div class="intro">
            <p>弘新智能科技(武汉)有限公司(简称“弘新智能”)是一家围绕视频分析技术为核心，以数据应用为导向的视频硬件及系统提供商。</p>
            <p>
              团队专注于计算机视觉、模式识别领域的开发与研究，核心技术涵盖人工智能、边缘计算、物联网等领域。此外，弘新智能旗下产品还包括视频融合平台、智慧环保管理平台、智慧校园管理平台、公共法律服务平台及解决方案，覆盖政府、环保、教育、司法、公安等多个行业领域。
              基于市场需求，弘新智能还将提供智能分析系统的研发及软件的个性化定制等服务，致力于通过智能分析产品为客户带来更智能化、更高效的应用体验。</p>
          </div>
        </el-col>
      </el-row>
      <el-row class="kind">
        <el-col :span="6">
          <img src="../assets/images/item_icon9.png" alt=""/>
          <p>创新进取</p>
          <span>以积极向上的心态面对一切挑战与变革，并在不断的变化中进行改进优化，以不甘于墨守成规、一成不变的精神实现自我提升和突破。</span>
        </el-col>
        <el-col :span="6">
          <img src="../assets/images/item_icon10.png" alt=""/>
          <p>精益求精</p>
          <span>对于工作或事件通过复盘、总结的方式将优秀模式复用在其他同类工作中，实现组织与个人的不断成长，面对新事物保持独立思考习惯，不盲从权力与权威、不依附观点、不主观臆断。</span>
        </el-col>
        <el-col :span="6">
          <img src="../assets/images/item_icon11.png" alt=""/>
          <p>用心服务</p>
          <span>敢于承担责任，不逃避挑战性工作，对于分工不明地带能够主动承担推进，遇到问题不推诿以解决问题为第一处理目标。</span>
        </el-col>
        <el-col :span="6">
          <img src="../assets/images/item_icon12.png" alt=""/>
          <p>客户为先</p>
          <span>尊重客户，及时响应客户需求，快速解决客户问题，站在客户立场思考和推进工作，深入了解客户需求的变化，提供超出客户预期的产品和服务。</span>
        </el-col>
      </el-row>
    </div>
    <el-row class="gray_bg">
      <div class="content">
        <el-row class="column">
          <p>荣誉资质<i>HONOR</i></p>
        </el-row>
        <el-carousel indicator-position="outside" :interval="5000" class="honor">
          <el-carousel-item>
            <el-row :gutter="10">
              <el-col :span="4">
                <div class="image_preview">
                  <img src="../assets/images/soft1.jpg" alt=""/>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="image_preview">
                  <img src="../assets/images/soft2.jpg" alt=""/>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="image_preview">
                  <img src="../assets/images/soft3.jpg" alt=""/>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="image_preview">
                  <img src="../assets/images/soft4.jpg" alt=""/>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="image_preview">
                  <img src="../assets/images/soft5.jpg" alt=""/>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="image_preview">
                  <img src="../assets/images/soft6.jpg" alt=""/>
                </div>
              </el-col>
            </el-row>
          </el-carousel-item>
          <el-carousel-item>
            <el-row :gutter="10">
              <el-col :span="4">
                <div class="image_preview">
                  <img src="../assets/images/soft7.jpg" alt=""/>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="image_preview">
                  <img src="../assets/images/soft8.jpg" alt=""/>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="image_preview">
                  <img src="../assets/images/soft9.jpg" alt=""/>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="image_preview">
                  <img src="../assets/images/soft10.jpg" alt=""/>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="image_preview">
                  <img src="../assets/images/soft11.jpg" alt=""/>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="image_preview">
                  <img src="../assets/images/soft12.jpg" alt=""/>
                </div>
              </el-col>
            </el-row>
          </el-carousel-item>
        </el-carousel>
      </div>
    </el-row>
    <div class="content">
      <el-row class="column">
        <p>联系我们<i>CONTACT US</i></p>
      </el-row>
      <el-row :gutter="20" class="contact">
        <el-col :span="12" class="company_left">
          <p class="name">弘新智能科技(武汉)有限公司</p>
          <el-row class="company_info">
<!--            <p>-->
<!--              <img src="../assets/images/contact_icon1.png" alt=""/>-->
<!--              <span>商务合作：13100645321(谭经理)</span>-->
<!--            </p>-->
            <p>
              <img src="../assets/images/contact_icon2.png" alt=""/>
              <span>联系电话：027-59726266</span>
            </p>
            <p>
              <img src="../assets/images/contact_icon3.png" alt=""/>
              <span>公司地址：武汉市光谷大道35号光谷总部时代二期5栋10楼</span>
            </p>
            <!--            <p>-->
            <!--              <img src="../assets/images/contact_icon4.png" alt=""/>-->
            <!--              <span>公司官网：https://wise777.com</span>-->
            <!--            </p>-->
          </el-row>
        </el-col>
        <el-col :span="12">
          <!--          <img class="company" src="../assets/images/map.png" alt=""/>-->
          <el-form :model="messageForm" ref="messageForm" class="message_form" label-width="100px">
            <el-form-item label="姓名" prop="name">
              <el-input v-model.trim="messageForm.name" placeholder="请输入姓名" maxlength="10"></el-input>
            </el-form-item>
            <el-form-item label="手机号码" prop="phone">
              <el-input v-model.trim="messageForm.phone" placeholder="请输入手机号码" maxlength="11"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code">
              <el-input v-model.trim="messageForm.code" placeholder="请输入验证码" maxlength="6"
                        style="margin-right: 22px; width: calc(100% - 134px);"></el-input>
              <el-button type="primary" plain>获取验证码</el-button>
            </el-form-item>
            <el-form-item label="留言内容" prop="content">
              <el-input v-model.trim="messageForm.content" :rows="5" placeholder="请输入留言内容" type="textarea"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary">提交留言</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </el-row>
</template>

<script>
export default {
  name: 'About',
  components: {},
  data() {
    return {
      messageForm: {
        name: '',
        phone: '',
        code: '',
        content: ''
      },
    }
  }
}
</script>

<style>
.company {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border: 1px solid #e1e6ff;
}

.intro {
  color: #858ab4;
  line-height: 2;
  text-indent: 2em;
}

.intro p {
  margin: 15px 0;
}

.kind {
  padding: 40px 0;
}

.kind .el-col-6 {
  padding: 0 20px;
  text-align: center;
  line-height: 1.5;
}

.kind .el-col-6 img {
  width: 80px;
  height: 80px;
  padding: 10px;
  border: 2px dotted #a4bbfd;
  border-radius: 50%;
}

.kind .el-col-6 p {
  margin: 15px 0;
  font-size: 24px;
}

.kind .el-col-6 span {
  color: #858ab4;
  font-size: 14px;
  line-height: 2;
}

.honor {
  margin-bottom: 40px;
}

.honor .el-carousel__container {
  height: 280px;
}

.image_preview {
  height: 250px;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #e1e6ff;
  border-radius: 6px;
}

.image_preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contact {
  margin-bottom: 40px;
}

.company_left {
  height: 300px;
  display: flex;
  flex-flow: row wrap;
  align-content: space-around;
}

.name {
  font-size: 22px;
}

.company_info p {
  display: flex;
  align-items: center;
  color: #858ab4;
}

.company_info img {
  margin-right: 10px;
}
</style>
